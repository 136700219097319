<template>
  <div class="columns">
    <div class="column is-one-third">
      <card-component
        title="1. Cut clusters"
        class="tile is-child"
        :displayHelp="$options.name"
      >
        <validation-observer v-slot="{ handleSubmit }">
          <validation-provider
            v-slot="{ errors, valid}"
            :rules= {required:true,integer:true,min_value:2,max_value:20}
            name="Number of clusters"
          >
            <b-field
              label="Number of clusters"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                v-model="nbCluster"
                expanded
              ></b-input>
            </b-field>
          </validation-provider>
          <hr />
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-search"></i></span>
              <span> Explore </span>
            </b-button>
          </div>
          </validation-observer>
        </card-component>
    </div>
    <div class="column">
      <card-component title="Super clustering on SOM prototypes">
      </card-component>
      <card-component v-if="!resultsLoaded">
        <plotly
          v-if="graphicalssuper.length>0"
          :data="graphicalssuper[0]"
        ></plotly>
      </card-component>
      <div v-if="resultsLoaded">
        <error :type='"notifications"' />
        <card-component>
          <b-tabs @input="displayResult">
            <b-tab-item label="Plots" icon="bar-chart" value="plots" />
            <b-tab-item label="Frequency table" icon="table" value="clustersummary" />
            <b-tab-item label="Super clusters" icon="th" value="clusterview" />
          </b-tabs>
        </card-component>
        <card-component v-show="currentResult==='plots'">
          <plotly
              v-if="graphicals.length===1"
              :data="graphicals[0]"
            ></plotly>
            <plotly-list
              v-else-if="graphicals.length>1"
              :data="graphicals"
            ></plotly-list>
        </card-component>
        <card-component
            v-if="currentResult==='clusterview'"
            :title="clusterView.title"
          >
          <data-view :data="clusterView" :showClassInfo="true" :sort="true"/>
          <br>
          <r-extract-cluster :datasetName="cutObjectName" :baseName="datasetUserName"></r-extract-cluster>
        </card-component>
        <card-component
            v-show="currentResult==='clustersummary'"
            :title="clusterSummary.title"
          >
        <basic-table :data="clusterSummary" :showClassInfo="true" :sort="true"/>
        </card-component>
      </div>
    </div>
  </div>
</template>

<script>
import { ranalysis } from '@/mixins/ranalysis'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'RPlotSomSuper',
  props: {
    objectSom: {
      type: String,
      default: ''
    },
    dataset: {
      type: String,
      default: ''
    }
  },
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'data-view': () => import('@/components/rresults/DataView.vue'),
    'basic-table': () => import('@/components/rresults/BasicTable.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'error': () => import('@/components/ui/Error.vue'),
    'r-extract-cluster': () => import('@/components/ranalyses/RExtractDataset.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      nbCluster: 2,
      graphicalssuper: [],
      datasetName: '',
      cutObjectName: '',
      currentResult: 'plots'
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  mounted () {
    if (this.analysisObj === null) {
      this.runInit()
    }
  },
  computed: {
    params: function () {
      let params = {
        'func_name': 'r_cut_supercluster',
        'datasetName': this.datasetName,
        'k': parseInt(this.nbCluster),
        'out_graph': true
      }
      return params
    },
    clusterSummary: function () {
      return this.basicTables.filter(el => el.title.startsWith('Frequency table of'))[0]
    },
    clusterView: function () {
      return this.dataViews.filter(el => el.title.startsWith('View of an extract of dataset'))[0]
    },
    datasetUserName: function () {
      let origin = this.workspaceStore.analyses.filter(obj => obj.object_name === this.objectSom)[0].origin_dataset
      return this.workspaceStore.datasets.filter(obj => obj.object_name === origin)[0].user_name
    }
  },
  methods: {
    displayResult: function (value) {
      this.currentResult = value
    },
    runInit: function () {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.workspaceStore.runRFunction({
        'func_name': 'r_super_cluster',
        'datasetName': this.objectSom,
        'out_graph': true
      })
        .then(data => {
          for (let key of Object.keys(data)) {
            let jresult = data[key]
            if (jresult.type === 'PlotLy') {
              this.graphicalssuper.push(jresult)
            }
            this.datasetName = data.ObjectName
          }
          loadingComponent.close()
        })
        .catch(() => {
          loadingComponent.close()
        })
    },
    getAvailableVariables: function (dataset) {
      let variables = []
      if (dataset in this.allVarnames) {
        variables = this.allVarnames[dataset].map(obj => obj.name)
      }
      return variables
    },
    selectVarname: function (value, varname) {
      this[varname] = value.name
    },
    beforeRun: function () {
      this.cutObjectName = null
      this.newDatasetName = ''
      this.currentResult = 'plots'
    },
    afterRun: function (data) {
      this.newDatasetName = data.ObjectName
      this.cutObjectName = data.ObjectName
    }
  }
}
</script>
